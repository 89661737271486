//custom.scss


$primary: #d0daee;
$secondary: #263C5C;
$success: #59dcd5;
$danger: #FD7972;
$warning: #FF965D;
$light: #F8F8F8;
$body-color: #d0daee;



@import "../../node_modules/bootstrap/scss/bootstrap";