.inactive-filter {
  filter: brightness(50%);
  transition: 250ms ease-in-out;
}

.active-container {
  background-color: #cdcdcd0c;
  border-radius: 15px;
  transition: 250ms ease-in-out;
}

.container {
  transition: 250ms ease-in-out;
}

.skill-container {
  background-color: #4ac96e1a;
  /* border: 1px #4ac96e solid; */
  border-radius: 15px;
  font-size: .875rem;
  height: fit-content;
}