@font-face {
  font-family: Inter-medium;
  src: url('../typography/inter/Inter\ Web/Inter-Regular.woff');
}


body {
  background: radial-gradient(at top left,
      #182848, #0e1729);
  background-color: #0e1729;
  font-family: 'Prata';
  background-repeat: no-repeat;
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}

.default-cursor {
  &:hover {
    cursor: default;
  }
}

.experience-section {
  margin-top: 7rem
}

.name-sticky-container {
  position: sticky;
  top: 25px
}

.font-small {
  font-size: .875rem;
}

.link-icon {
  vertical-align: top;
}