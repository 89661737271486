.section-bar {
  height: 1px;
  width: 45px;
  background-color: #d0daeebf;
  transition: 150ms ease-in-out
}

.section-bar-active {
  height: 2px;
  width: 75px;
  background-color: #d0daee;
  transition: 150ms ease-in-out
}

.link-section {
  margin-top: 7rem;
  margin-bottom: 14rem
}